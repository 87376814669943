module.exports = [
    {
        icon: '👋',
        group: 'Hands and other Body Parts Emojis',
        emojis: ['👋', '🤚', '🖐', '✋', '🖖', '👌', '🤌', '🤏', '✌', '🤞', '🤞', '🤟', '🤘', '🤙', '👈', '👉', '👆', '👇', '🖕', '☝', '👍', '👎', '✊', '👊', '🤛', '🤜', '👏', '🙌', '👐', '🤲', '🤝', '🙏', '✍']
    },
    {
        icon: '🙂',
        group: 'Smiley Face',
        emojis: ['🙂', '😀', '😃', '😄', '😁', '😅', '😆', '🤣', '😂', '🙃', '😉', '😊', '😇', '😎', '🤓', '🧐', '🥳', '🥰', '😍', '🤩', '😘', '😗', '😚', '😙', '🥲'],
    },
]

// ,'😋', '😛', '😜', '🤪', '😝', '🤑', '🤗', '🤭', '🤫', '🤔', '😪', '😴', '😌', '😔', '🤤', '😷', '🤒', '🤕', '🤢', '🤮', '🤧', '🥵', '🥶', '🥴', '😵', '🤯', '☺'

// {
//     icon: '😕',
//     group: 'Concerned Faces Emojis',
//     emojis: ['😕', '😟', '🙁', '😮', '😯', '😲', '😳', '🥺', '😦', '😧', '😨', '😰', '😥', '😢', '😭', '😱', '😖', '😣', '😞', '😓', '😩', '😫', '🥱', '😤', '😡', '😠', '🤬', '😈', '👿', '💀', '☠', '☹'],
// },

// , '💅', '🤳', '💪', '🦾', '🦵', '🦿', '🦶', '👂', '🦻', '👃', '🧠', '👣', '🫀', '🫁', '🦷', '🦴', '👀', '👁', '👅', '👄'