<template>
    <b-modal id="modal-image-preview" scrollable title="Pesan Gambar" hide-footer>
        <form class="form-horizontal form-material">
                <img class="images-preview-modal img-responsive" v-bind:src="imageUrl">
                <div class="img-caption">{{ caption }}</div>
        </form>
    </b-modal>
</template>

<script>
    export default {
        props: ['imageUrl', 'caption']
    }
</script>